<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col cols="12" v-if="filtrosShow == true">
          <v-row>
            <v-col>
              <dos-fechas
                :fechas="[
                  FuncionesGenerales.formatoFecha(filtros.fecha.desde, 4),
                  FuncionesGenerales.formatoFecha(filtros.fecha.hasta, 4),
                ]"
                @getFechas="getFechas"
              ></dos-fechas>
            </v-col>
            <v-col>
              <select-grupo-negocio
                :SelectDefault="1"
                :todos="false"
                @getSelect="getGrupoNegocio"
                :dense="true"
                :outlined="true"
              ></select-grupo-negocio>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="text-right" v-if="filtrosShow == true">
          <v-btn @click="cargarDatos(filtros)" x-small color="primary">Buscar</v-btn>
        </v-col>

        <v-col cols="12">
          <tabla-simple-reporte
            @GetVer="GetVer"
            :acciones="['VER']"
            :height="400"
            :nombreReporte="
              'Arqueo de caja por dia ' +
              filtros.grupo_negocio.descripcion +
              ' ' +
              FuncionesGenerales.formatoFecha(filtros.fecha.desde, 4) +
              ' al ' +
              FuncionesGenerales.formatoFecha(filtros.fecha.hasta, 4)
            "
            :cabeceraIn="cabecera"
            :datosIn="datos"
            :exportar="exportar"
          ></tabla-simple-reporte>
        </v-col>
      </v-row>
    </v-card-text>
    <modal-ver-comanda-vue
      :comanda="comandaSelect"
      :soloVer="true"
      :openModal="openModalComanda"
      @GetOpenModal="GetOpenModalComanda"
    >
    </modal-ver-comanda-vue>
  </v-card>
</template>
  
<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import MaestroService from '@/api/servicios/MaestroService'
import TablaSimpleReporte from '@/components/TablaSimpleReporte.vue'
import FuncionesGenerales from '@/funciones/funciones'
import DosFechas from '@/components/DosFechas.vue'
import SelectGrupoNegocio from '@/components/SelectGrupoNegocio.vue'
import ModalVerComandaVue from '../../../../dashboard/componentes/ModalVerComanda.vue'
// demos

export default {
  components: { TablaSimpleReporte, DosFechas, SelectGrupoNegocio, ModalVerComandaVue },
  props: {
    filtrosShow: Boolean,
    exportar: Array,
  },
  setup() {
    const cabecera = ref([])
    const datos = ref([])
    const grupoNegocio = ref({})
    const filtros = ref({
      fecha: {
        desde: new Date(),
        hasta: new Date(),
      },
      id_grupo_negocio: 1,
      grupo_negocio: { descripcion: '2' },
    })
    const openModalComanda = ref(false)
    const comandaSelect = ref(null)
    const GetVer = item => {
      console.log(item.id_comanda)
      comandaSelect.value = { id: item.id_comanda }
      openModalComanda.value = true
    }
    const GetOpenModalComanda = datos => {
      openModalComanda.value = false
    }
    const cargarDatos = param => {
      filtros.value = param
      MaestroService.RptDetalladoComandasDia(param)
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            if (response.data.datos.datos.length > 0) {
              cabecera.value = [
                { text: 'Id', value: 'id_comanda' },
                { text: 'Cliente', value: 'cliente', visible: false },
                { text: 'Fecha', value: 'fecha' },
                { text: 'Caja', value: 'nombre_caja' },
                { text: 'Empleado', value: 'empleado' },

                { text: 'Fiscalizado', value: 'fiscalizado', visible: false },
                { text: 'Sub Total', value: 'Sub Total', visible: false },
                { text: 'Iva', value: 'Iva', visible: false },
                { text: 'Total', value: 'total' },
              ]
              response.data.datos.columnas.split(',').forEach(col => {
                cabecera.value.push({ text: FuncionesGenerales.camelCase(col.replace(/_/g, ' ')), value: col })
              })
              cabecera.value.push({ text: 'Monto Total', value: 'pago_real' })
              datos.value = response.data.datos.datos
              cabecera.value.push({ text: 'Acciones', value: 'accion' })
            } else {
              // se econcontro resultado")
            }
          }
        })
        .catch(err => {
          console.error(err)
          store.commit('setAlert', {
            message: err,
            type: 'error',
            error: {
                        ...err, 
                        response: error?.response?.data
                    },
            funcion: 'cargarDatos',
          })
        })
        .finally(() => {})
    }

    const getFechas = fecha => {
      filtros.value.fecha.desde = fecha.desde
      filtros.value.fecha.hasta = fecha.hasta
      cargarDatos(filtros.value)
    }

    const getGrupoNegocio = dato => {
      grupoNegocio.value = dato
      filtros.value.id_grupo_negocio = dato.id
      filtros.value.grupo_negocio = dato
      cargarDatos(filtros.value)
    }

    const exportExcelExterno = () => {
      let dato = {
        nombreHoja: 'Arqueo caja',
        cabecera: [],
        rows: [],
        anchoColumanas: [{ width: 20 }, { width: 30 }, { width: 30 }],
      }

      cabecera.value.forEach(col => {
        dato.cabecera.push(col.text)
      })

      let row = []
      datos.value.forEach(ro => {
        row = []
        cabecera.value.forEach((col, i) => {
          if (i >= 1) {
            row.push({
              v: ro[col.value],
              t: 'n',
              s: {
                numFmt: '0.00',
              },
            })
          } else {
            row.push({
              v: ro[col.value],
              t: 's',
            })
          }
        })

        dato.rows.push(row)
      })

      return dato
    }

    return {
      cabecera,
      datos,
      cargarDatos,
      filtros,
      getFechas,
      FuncionesGenerales,
      getGrupoNegocio,
      exportExcelExterno,
      openModalComanda,
      comandaSelect,
      GetOpenModalComanda,
      GetVer,
    }
  },
}
</script>
  